/* reactHeader.cs */

.image-selectors {
  margin-top: 50px;
  display: flex;
  gap: 10px;
}

.image-selector {
  width: 15px;
  height: 15px;
  border-radius: 25%;
  background-color: gray;
  border: black;
  cursor: pointer;
  transition: background-color 0.5s;
}

.image-selector.active {
  background-color: #007bff; /* Highlight color for active selector */
}

.image-selector:hover {
  background-color: #0056b3;
}

.artist-caption-bottom-left {
  position: absolute;
  bottom: 400px;
  right: 700px;
  color: black;
  font-size: 25px;
  font-weight: bold;
  /*background-color: rgba(255, 255, 255, 0.7); /* Optional: Adds a background for better readability */
  padding: 15px 10px;
  border-radius: 5px; /* Optional: Rounds the corners */
}