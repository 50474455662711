.promo-banner {
  background-color: #f9fafc;
  padding: 40px;
  border-radius: 10px;
}

.promo-text h1 {
  font-size: 2.0rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #0b2948;
}

.promo-text p {
  font-size: 1.1rem;
  color: #6c757d;
}

.promo-buttons .create-event-btn {
  background-color: #ffcc33;
  border: none;
  font-weight: bold;
  margin-right: 15px;
}

.promo-buttons .buy-tickets-btn {
  border-color: #0b2948;
  color: #0b2948;
  font-weight: bold;
}

.promo-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo-card {
  width: 100%;
  border: none;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.promo-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 250px;
  object-fit: cover;
}

.event-list {
  list-style-type: none;
  padding: 0;
}

.event-list li {
  font-size: 1rem;
  color: #0b2948;
  margin-bottom: 5px;
}

.promo-card .btn-warning {
  width: 100%;
  margin-top: 15px;
}