/* bootstrap-custom.css */

/* Add Bootstrap CSS here */
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');

/* Add custom styles below */
/* For example: */
.custom-button {
  background-color: blue;
  color: white;
}
