/* TicketSalesPage.css */
.carousel-wrapper {
  max-width: 1024px;
  margin: auto;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}
