.glow-bounce {
  font-weight: bold;
  color: #007bff;
  text-shadow: 0 0 8px rgba(0, 123, 255, 0.6), 0 0 12px rgba(0, 123, 255, 0.4);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}