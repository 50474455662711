/* index.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeserva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kavoon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.montserrat-font {
  font-family: 'Montserrat', sans-serif;
}

.yeserva-font {
  font-family: 'Yeserva One', cursive;
}

.creepster-font {
  font-family: 'Creepster', cursive;
}

.kavoon-font {
  font-family: 'Kavoon', cursive;
}

.transparent-bg {
  background-color: rgba(255, 255, 255, 0.15); /* Adjust the alpha value to set the level of transparency */
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.85); /* Adjust the values to set the offset, blur radius, and color of the shadow */
}

.dropdown {
  position: relative;
}

.dropdown-select {
  padding-right: 30px; /* Adjust as needed */
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the icon does not interfere with select element */
}

.text-shadow span {
  display: inline-block; /* Scope to text-shadow spans only */
}

.yeseva-one-regular {
  font-family: "Yeseva One", serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-font {
  font-family: 'Montserrat', sans-serif;
}

span {
  display: inline-block;
}