/* EventList.css */

/* The responsive-container class ensures that the container 
   takes up the full width of its parent on smaller screens,
   and 90% of the width on screens larger than 992px. 
   This provides a flexible layout that adapts to various screen sizes. */

/* Ensure that each card maintains a consistent width and height */
/* Ensure each card maintains consistent width and height */
.event-card {
   height: 600px; /* Set a fixed height for consistency */
   display: flex;
   flex-direction: column;
   overflow: hidden;
}

/* Style for the poster image within each card */
.event-poster {
   height: 350px; /* Set fixed height for poster image */
   object-fit: cover; /* Ensures image fills the area without distortion */
   width: 100%; /* Make the image take full width */
}

/* Responsive container adjustments */
.responsive-container {
   width: 100%;
   padding: 20px;
}


@media (min-width: 992px) {
   .responsive-container {
      width: 90%;
   }
}

/* Adjust card header for consistent spacing */
.card-header {
   padding: 10px;
   font-size: 1.2rem;
}

h4.mb-0 {
   margin-bottom: 0 !important;
}




/* Truncate text in card body if it overflows */
.truncated-text {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

/* Ensure consistent spacing and layout in the card body */
.card-body-content {
   flex: 1;
   display: flex;
   flex-direction: column;
   overflow: hidden;
}