/* ReactNavbar.css */

.navbar {
  flex-wrap: nowrap;
}

.nav-link {
  white-space: nowrap;
  color: #ffffff !important; /* Ensure nav links are white */
}

.sign-in-link {
  color: #ffffff !important; /* Original color for the Sign In/Register link */
}

.nav-link:hover {
  color: #ddd !important; /* Hover color to differentiate interaction */
}

@media (max-width: 768px) {
  .navbar-collapse {
    flex-grow: 1;
    justify-content: center;
  }
  
  .nav-link {
    display: block;
    margin: 5px 0;
  }
  
  .navbar-nav {
    text-align: center;
  }
  
  .user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
}

@media (min-width: 769px) {
  .user-info {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

/* Default styling for larger screens */
.user-badge {
  position: absolute;
  top: 20px;
  right: 0;
}

/* Adjust for smaller screens */
@media (max-width: 576px) { /* Adjust max-width as needed for your design */
  .user-badge {
    position: static; /* or other positioning that fits your design */
    text-align: center;
    margin-top: 10px;
  }
}

/* Default styling */
.user-badge {
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes user badge to the far right */
}

.welcome-text {
  color: #b0c4de; /* Adjust to match your design */
  font-weight: bold;
}

.sign-out-button {
  color: #b0c4de; /* Adjust color to match your design */
  font-weight: bold;
}

/* Adjust for mobile view */
@media (max-width: 576px) {
  .user-badge {
    flex-direction: column;
    align-items: flex-end;
  }
  .welcome-text, .sign-out-button {
    font-size: 14px; /* Smaller font for mobile */
    color: #b0c4de; /* Adjust color for contrast */
  }
  .sign-out-button {
    margin-top: 5px; /* Add spacing between welcome text and sign-out */
  }
}
