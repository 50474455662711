/* EventHostingPage.css */
.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel {
  width: 100%;
  max-width: 800px; /* Adjust the max width as needed */
  margin: 0 auto;
}

/* EventHostingPage.css */
.carousel-controls-outside .carousel-control-prev,
.carousel-controls-outside .carousel-control-next {
  width: 5%;
  font-size: 1.5rem;
  color: #add8e6; /* Light blue color */
}

.carousel-controls-outside .carousel-control-prev {
  left: -50px; /* Adjust the value as needed to position the arrow outside */
}

.carousel-controls-outside .carousel-control-next {
  right: -50px; /* Adjust the value as needed to position the arrow outside */
}

.carousel-controls-outside .carousel-control-prev-icon,
.carousel-controls-outside .carousel-control-next-icon {
  background-color: #3003fc; /* Light blue color */
  border-radius: 50%;
}
