/* UserPanel.css */

/* Ensure buttons have equal height */
.button-equal-height {
  height: 50px; /* Set a fixed height for buttons */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive container for buttons */
.button-group .col {
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  /* Ensure fluid and responsive design on smaller screens */
  .button-group .col {
    margin-bottom: 15px;
  }
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Table adjustments */
.table-fluid {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .btn-sm {
    width: 100%; /* Make buttons full width on small screens */
    margin-bottom: 5px; /* Add spacing between stacked buttons */
  }
}

.placeholder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-color: #f8f9fa; /* Light grey background */
  border: 1px solid #ddd; /* Optional border for definition */
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  color: #6c757d; /* Muted text color */
}

.placeholder-icon {
  font-size: 50px;
  font-weight: bold;
  color: #6c757d; /* Muted grey */
  margin-bottom: 10px;
}

.placeholder-container h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.placeholder-container p {
  font-size: 14px;
  color: #6c757d;
}